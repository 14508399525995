import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';

import { iconSquare, title, subtitle } from './mainFeatures.module.scss';
import classNames from 'classnames';
import { backgroundIntentMap, textIntentMap } from '../../constants';

export function MainFeatures({ intent, elements = [] }) {
   if (!elements.length) {
      return null;
   }

   return (
      <Row className="py-5">
         {elements.map((element, idx) => (
            <Col key={idx} className={'d-flex align-items-start mb-4'} sm={12} md={6} lg={4}>
               <div className={classNames(iconSquare, backgroundIntentMap[intent], textIntentMap[intent], 'flex-shrink-0', 'me-3')}>
                  <FontAwesomeIcon icon={element.icon} />
               </div>
               <div>
                  <h2 className={title}>{element.title}</h2>
                  <p className={subtitle}>{element.subtitle}</p>
               </div>
            </Col>
         ))}
      </Row>
   );
}
