import React from 'react';
import { Parallax } from 'rc-scroll-anim';

export function ParallaxContentWrapper({ children }) {
   return (
      <Parallax always={true} animation={{ y: 0, opacity: 1, playScale: [0, 0.7] }} style={{ transform: 'translateY(80px)', opacity: 0 }}>
         {children}
      </Parallax>
   );
}
