import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ParallaxContentWrapper } from './animation/ParallaxContentWrapper';
import { SectionHeader } from './SectionHeader';

import * as classes from './featureSections.module.scss';

export function FeatureSections({ content, variant }) {
   if (!content.length) {
      return null;
   }

   return (
      <>
         {content.map(({ node }, idx) => {
            const content = <div dangerouslySetInnerHTML={{ __html: node.html }}></div>;
            const imageData = getImage(node.frontmatter.image);
            const icon = <FontAwesomeIcon icon={node.frontmatter.icon} size="3x" className={classNames('mt-1', `text-${variant}`)} />;

            // const isEven = idx % 2 === 0;

            if (node.frontmatter.isHeader) {
               return (
                  <section key={idx} id={idx}>
                     <SectionHeader content={content} />
                  </section>
               );
            }
            return (
               <div className="dark-background py-5" key={idx}>
                  <Container>
                     <section id={idx}>
                        <ParallaxContentWrapper>
                           <Row>
                              <Col xs={12} md="auto" className="mb-3">
                                 {icon}
                              </Col>
                              <Col className={classes.content}>{content}</Col>
                              <Col sm={12} md={6}>
                                 {imageData && <GatsbyImage image={imageData} imgClassName="image-style" alt={node.frontmatter.imageAlt} />}
                              </Col>
                           </Row>
                        </ParallaxContentWrapper>
                     </section>
                  </Container>
               </div>
            );
         })}
      </>
   );
}
