import React from 'react';

import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { PlayoutHeader } from '../components/playout/PlayoutHeader';
import { FlowToContact } from '../components/global/FlowToContact';
import { FeatureSections } from '../components/global/FeatureSections';
import { Container } from 'react-bootstrap';

function Playout({ data }) {
   const [t] = useTranslation('playout');

   const { contentFeatures } = data;

   return (
      <>
         <Helmet>
            <title>{t('meta.title')}</title>
            <meta name="description" content={t('meta.description')} />
            <meta name="keywords" content={t('meta.keywords')} />
         </Helmet>

         <Container>
            <PlayoutHeader />
         </Container>
         <FeatureSections variant="vture-orange" content={contentFeatures.edges} />
         <Container>
            <FlowToContact variant="vture-orange" />
         </Container>
      </>
   );
}

export default Playout;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      contentFeatures: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/playout" }, type: { eq: "features" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  icon
                  order
                  language
                  isHeader
                  group
               }
            }
         }
      }
   }
`;
